(<template>
  <div class="awarded-actions">
    <div class="awarded-actions__button-wrapper">
      <button v-if="showFinishButton"
              class="sk-btn sk-btn--default awarded-actions__btn"
              @click="finishJob">{{ $gettext('Click here to finish the assignment') }}</button>
      <button v-if="isNormalAcceptedJob && userIsInterpreter"
              class="sk-btn sk-btn--red awarded-actions__btn"
              @click="checkWithdrawTime">{{ $gettext('Withdraw from assignment') }}</button>
      <a v-if="supportPortalLink"
         :href="supportPortalLink"
         class="sk-btn sk-btn--white awarded-actions__btn"
         target="_blank">{{ $gettext('Frequently asked questions') }}</a>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import JobActionsPrototype from '@/prototypes/JobActionsPrototype';

  export default {
    extends: JobActionsPrototype,
    data() {
      return {
        supportPortalLink: this.$getString('domainData', 'supportPortalLink')
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter'])
    }
  };
</script>

<style scoped>
  .awarded-actions {
    display: flex;
    margin-top: 30px;
  }

  .awarded-actions__button-wrapper {
    display: block;
    width: 100%;
    max-width: 265px;
  }

  .awarded-actions__btn {
    margin-bottom: 20px;
  }

  .awarded-actions__btn:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    .awarded-actions {
      flex-wrap: wrap;
    }

    .awarded-actions__button-wrapper {
      max-width: 100%;
    }
  }
</style>
